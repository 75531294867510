<template>
    <div class="chat_component">
        <h3>{{$t('Live chat')}}</h3>
        <div :id="blockId" class="chat_wrapper" @keypress.enter="sendOnEnter"></div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  const CHAT_URL = "https://visaic.com.ua:3000"
  const USER_CHAT_URL = `${CHAT_URL}/js/client.js`
  const ADMIN_CHAT_URL = `${CHAT_URL}/js/admin.js`
  export default {
    name: "ChatComponent",
    data(){
      return{
        blockId: 'event_chat',
        sendButton: null,
      }
    },
    props:{
      event: {}
    },
    methods:{
      getChatOptions(){
        const {first_name, last_name, email} = (this.user || {})
        return {
          'url': CHAT_URL,
          'userId': email,
          'user_name': `${first_name || ""} ${last_name || ""}`,
          'chatId': this.event.id,
          'placeholder': this.blockId,
        }
      },
      findSendButton(){
        this.sendButton = document.querySelector(`#${this.blockId} button#visaic-messages-send` )
      },
      sendOnEnter(e){
          e.stopPropagation();
          e.preventDefault();
          this.sendButton?.click?.()
      }
    },
    mounted() {
      const isAdmin = this.user?.is_admin;
      const scriptPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        document.body.appendChild(script);
        script.onload = resolve;
        script.onerror = reject;
        script.async = true;
        isAdmin? (script.src = ADMIN_CHAT_URL) : (script.src = USER_CHAT_URL)
      });
      scriptPromise.then((res) => {
        const VisaicChat = window.VisaicChat
        const options = this.getChatOptions();
        if (VisaicChat){
          isAdmin? new VisaicChat.Admin(options): new VisaicChat.Chat(options);
          this.findSendButton()
        }
      }).catch((e) => {
        console.log('scriptPromise e', e);
      })
    },
    computed:{
      ...mapState({
        user: state => state.auth.user,
      })
    }
  }
</script>

<style lang="scss">
    @import "../../assets/css/colors";
    .chat_component{
        border: 1px solid $text-tertiary-light;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3{
            background: $text-tertiary-light;
            padding: .5rem;
            font-size: 1.5rem;
            text-transform: none;
        }
    }
    .chat_wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        #visaic-chat,
        #visaic-admin-chat{
            > div{
              margin-bottom: 0;
            }
        }
            #visaic-admin-chat.chat-inline,
            #visaic-chat.chat-inline{
            border: none;
            height: 100%;
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            ul{
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: auto;
                & > li {
                    margin: .1rem 0;
                }
                &::-webkit-scrollbar{
                    width: 4px;
                    height: 100%;
                    background: rgba($text-tertiary-dark-rgb, .1);
                }
                &::-webkit-scrollbar-thumb{
                    background: $text-tertiary-dark;
                    border-radius: 4px;
                }
            }
            li {
                text-align: left;
            }
            .message:not(.my) + .message.my{
                margin-top: 1rem;
            }
            .message.my + .message:not(.my) {
                margin-top: 1rem;
            }
            .message{
                display: flex;
                flex-direction: column;
                padding: .7rem;
                font-size: 1rem;
                letter-spacing: 0.02rem;
                float: left;
                line-height: 1;
                margin: .2rem;
                border-radius: 1rem;
                border-top-left-radius: 0;
                border-bottom-right-radius: 0;
                box-shadow: 0 0 2px rgba($text-tertiary-light-rgb,.6),  1px 1px 3px 3px rgba($text-tertiary-light-rgb,.6);
                .user_name{
                    font-weight: 600;
                    color: $text-tertiary-dark;
                    margin-bottom: .5rem;
                }
                .message-text{
                    line-height: 1.4;
                }
                &.my{
                    border-radius: 1rem;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 0;
                    .user_name{
                        visibility: hidden;
                        display: none;
                    }
                    float: right;
                }
            }
            #msg-undefined{
                background: rgba($error-rgb, .3);
                border-radius: .3rem;
            }
            ul + div,
            .visaic-message-placeholder{
                margin: 0;
                display: flex;
                background: rgba($tertiary-background-rgb, .5);
                > div,
                .visaic-message-box{
                    flex-grow: 1;
                }
                textarea{
                    height: 100%;
                    width: 100% !important;
                    outline: none;
                    resize: none;
                    padding: .5rem 1rem;
                    border: none;
                    background: transparent;
                }
                button {
                    display: block;
                    position: relative;
                    border: none;
                    background: transparent;
                    color: transparent;
                    overflow: hidden;
                    &:after{
                        content: '\27A4';
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        background-color: $text-tertiary-middle;
                        z-index: 1;
                        font-size: 2rem;
                        color: $text-secondary;
                        transform: translateX(-100%);
                        transition: all .15s .2s linear;
                    }
                    &:before{
                        content: '\27A4';
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: transparent;
                        z-index: 1;
                        font-size: 2rem;
                        color: $text-tertiary-middle;
                        transform: translateX(0);
                        transition: all .15s linear;
                    }
                    &:hover{
                        &:after{
                            transform: translateX(0);
                        }
                        &:before{
                            transform: translateX(100%);
                        }
                    }
                }
            }
        }
        #visaic-admin-chat.chat-inline{
            #visaic-messages-list{
                .message {
                    flex-direction: row;
                    align-items: flex-start;
                }
            }
        }

        #users-list-place{
            ul{
                margin-bottom: 0;
            }
            li {
                display: flex;
                gap: .3rem;
                &.user{
                    &:hover{
                        box-shadow: 1px 1px 6px $text-tertiary-light, 1px 1px 10px $text-tertiary-light, 0 0 2px 1px $text-tertiary-light;
                    }
                    &.my{
                      background: rgba($card-background-rgb,.4);
                    }
                    &.banned{
                        background: transparent;
                        color: $error
                    }
                }
            }
        }
    }


</style>