<template>
    <blocked-content-wrapper :blocked="blocked" class="home-container mb-5">
        <static-block-component page="event" position="top"/>
        <div v-if="event && event.participants ">
            <div v-if="loading">
                <loader/>
            </div>
            <div v-else class="player_wrapper">
                <div class="container" v-if="!event.is_geo_blocked">
                    <flip v-if="event.stream_status === 'upcoming'" @timeEnded="onTime"
                          :timestamp="event.event_utc_ts" :countDownPreview="countDownPreview" />
                    <template v-else>
                        <video-player v-if="!embedded" :options="videoOptions" :analytics-options="analyticsOptions"/>
                        <div v-else v-html="embedded"></div>
                        <button-component
                            @btn-click="toggleChat"
                            class="chat-toggle-btn"
                            v-if="chatIsEnabled"
                        >
                            {{$t(`${chatDisplayed? "Hide": "Show"} chat`)}}
                        </button-component>
                    </template>
                </div>
                <template v-if="chatIsEnabled" >
                        <div v-show="chatDisplayed" class="chat">
                            <chat-component :event="event"/>
                        </div>
                </template>
            </div>
        </div>
        <static-block-component page="event" position="bottom"/>
        <div  v-if="showEmbeddedPreview" class="embeddeb-preview" @click.stop.prevent="togglePreview(false)">
            <div class="content">
                <button-component
                    variant="icon"
                    class="closeIcon"
                    @btn-click="togglePreview(false)"
                >
                    <XMarkIcon/>
                </button-component>
                <embedded-player :link="embeddedPreview" :autoplay="true" class="preview-player"/>
            </div>
        </div>
    </blocked-content-wrapper>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import {MEDIA_TYPE, MEDIA_TYPE_NAME_BY_ID, STREAM_STATUS} from '@/const';
  import VideoPlayer from '../../components/video/VideoPlayer';
  import StaticBlockComponent from '../../components/StaticBlockComponent';
  import Flip from '../../components/video/Flip';
  import ChatComponent from "../components/ChatComponent";
  import ImageComponent from "../../components/base-components/ImageComponent";
  import BlockedContentWrapper from "../../components/base-components/BlockedContentWrapper";
  import ButtonComponent from "../../components/base-components/buttons/ButtonComponent";
  import XMarkIcon from "../../assets/img/XMarkIcon";
  import EmbeddedPlayer from "../../components/base-components/ebmeddedPlayer/embeddedPlayer";
  import Loader from "../../components/Loader";

  export default {
    name: 'WatchGameComponent',
    components: {
        Loader,
        EmbeddedPlayer,
        XMarkIcon,
        ButtonComponent,
        BlockedContentWrapper, ImageComponent, ChatComponent, Flip, VideoPlayer, StaticBlockComponent},
    data() {
      return {
        event: {},
        videoOptions: {
          autostart: true,
          file: null
        },
        analyticsOptions: {},
        showEmbeddedPreview: false,
        chatDisplayed: true,
        loading: false
      }
    },
    methods: {
      ...mapActions(['GET_EVENT']),
      getMediaByType(type){
        return this.event.media.find(item => Number(item.media_type) === Number(type))
      },
      getMediaPropFromType(mediaType, prop){
        const media = this.getMediaByType(mediaType)
        return prop? media?.[prop] : media;
      },
      onTime() {
          const tenSec = 10 * 1000;
          const randomCount = Math.round(Math.random() * tenSec)
          setTimeout(() => this.getEventInfo(), randomCount)
          this.loading = true;
      },
      getEventInfo() {
          this.loading = true;
        this.GET_EVENT(this.$route.params.id).then((data) => {
          const event = data.data;
          if (!event.has_access && !event.is_geo_blocked) {
            if (this.$store.getters.isLogged) {
              let path = '/select-package/' + data.data.id;
              this.$router.replace({path})
            } else {
              this.$router.push({name: 'login'})
            }
            return ;
          }
          this.event = event;
        }).catch((data) => {
          if (data.status == 404) {
            this.$router.push({name: 'ErrorPage'})
          }
        })
        .finally(() => {
            this.loading = false;
        });
      },
        togglePreview(show){
          this.showEmbeddedPreview = show;
          document.querySelector("body").classList.toggle("overflow-hidden", show);
        },
        toggleChat(){
          this.chatDisplayed = !this.chatDisplayed
        }
    },
    watch:{
      event(){
        if (this.blocked) return;

        const thumbnails = this.getMediaPropFromType(MEDIA_TYPE.thumbnails, 'media_url')
        if (thumbnails) {
          this.videoOptions.tracks = [{
            file: thumbnails,
            kind: 'thumbnails'
          }]
        }
        this.videoOptions.file = this.getMediaPropFromType(this.streamStatus, 'media_url');
        if (this.event){
          if (this.event.stream_status ==='live' && this.event.placeholder_live_comming){
            this.videoOptions.file = 'https://js-lib-yare-analytics.s3.us-west-2.amazonaws.com/placeholders/livestream_delayed.mp4';
          }
          if (this.event.stream_status ==='finished'){
            if(this.event.placeholder_vod_comming){
              this.videoOptions.file = 'https://js-lib-yare-analytics.s3.us-west-2.amazonaws.com/placeholders/archive_currently_unavailable.mp4';
            }
            if(this.event.placeholder_vod_no){
              this.videoOptions.file = 'https://js-lib-yare-analytics.s3.us-west-2.amazonaws.com/placeholders/event_unavailable.mp4';
            }
          }
        }

        this.analyticsOptions = {
          tag: this.event.stream_name,
          videoTitle: this.videoTitle,
          uniqueId: this.event.id,
          streamType: MEDIA_TYPE_NAME_BY_ID[this.getMediaPropFromType(this.streamStatus, "media_type")] || '',
        }
      }
    },
    computed: {
      ...mapState({
        channelSettings: state => state.channel.settings,
      }),
      streamStatus(){
        return this.event.stream_status === STREAM_STATUS.vod? MEDIA_TYPE.vod : MEDIA_TYPE.live
      },
      embedded() {
        return this.getMediaPropFromType(MEDIA_TYPE.embedded, 'media_info');
      },
      embeddedPreview(){
          return this.getMediaPropFromType(MEDIA_TYPE.embedded_preview, 'media_info');
      },
      videoTitle() {
        if (!this.event || !this.event.participants) {
          return ''
        }
        if (this.event.participants.length === 2){
          return `${this.trimParticipant(this.event.participants[0].name)} @ ${this.trimParticipant(this.event.participants[1].name)}`
        }
        return  this.event.name || '';

      },
      chatIsEnabled() {
        return !this.event.is_geo_blocked &&
            this.event?.stream_status === STREAM_STATUS.live &&
            this.event?.is_chat_enabled
      },
      eventImage() {
        return this.getMediaPropFromType(MEDIA_TYPE.image, 'media_url')
      },
      blocked(){
        return this.event?.is_geo_blocked
      },
      countDownPreview(){
        const content = this.channelSettings.content;
        return parseInt(content['event-count-down-preview']) || 0;
      }
    },
    created() {
      this.getEventInfo()
    }
  }
</script>

<style lang="scss" scoped>
    @import "src/assets/css/mixins";
    @import "src/assets/css/colors";
    .team-img {
        width: 90px;
        height: 90px;
    }
    .img-wrapper{
        max-width: calc(100vw - 1rem);
        max-height: 23rem;
        overflow: hidden;
        margin: 0 .5rem;
        position: relative;
        @include ratio(calc(16 / 9));
        &.small{
            max-height: 10rem;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .player_wrapper {
        display: flex;
        max-width: 98vw;
        gap: 1.5rem;
        margin: 0 auto;

        .container {
            flex-grow: 2.5;
            max-width: calc(98vw / 3.5 * 2.5);
        }

        .chat {
            flex-grow: 1;
        }

        @include media-max(lg) {
            flex-direction: column;
            .container {
                max-width: 100%;
            }
        }
    }

    .play-preview{
        position: absolute;
        z-index: 10;
        width: 3rem;
        height: 3rem;
        top: 50%;
        background: $play-button-color;
        border-radius: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .embeddeb-preview{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background: rgba($text-primary-rgb, .5);
        backdrop-filter: blur(10px);
        max-height: 100vh;
    }
    .content{
        position: relative;
        padding: 4rem;
        max-height: inherit;
    }
    .closeIcon{
        display: block;
        margin-left: auto;
        width: 3rem;
        height: 3rem;
        padding: .7rem;
    }
    .chat-toggle-btn{
        margin-top: 1rem;
        display: block;
        margin-left: auto;
        padding: .5rem 2rem;
    }
</style>
<style lang="scss">
    .preview-player {
        max-width: 99vw;
        position: relative;
        iframe{
            max-width: 95vw;
        }
    }
</style>