<template>
	<component v-if="player" :is="player" :link="link" :autoplay="autoplay"/>
</template>

<script>
  import Vue from 'vue';
  export default {
    name: "embeddedPlayer",
    props: {
      link: {
        type: String
      },
      autoplay:{
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        player: null,
      }
    },
    COMPONENTS_ARR: [
      {
        regEx: /youtu\.?be/,
        src:  './youtubeEmbeddedPlayer'
      }
    ],
    async created () {
      let component = this.$options.COMPONENTS_ARR.find(({regEx}) => (new RegExp(regEx, 'gmi')).test(this.link))
      if(component?.src){
        this.player = Vue.component('player', () => import(`${component.src}`).then(m => m.default))
      }
    }
  }
</script>

<style scoped>

</style>