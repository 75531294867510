<template>
    <div
        v-bind="$attrs"
        :class="{center: blocked}"
    >
        <div v-if="blocked" class="blocked_message">
            {{$t("GOE BLOCKED")}}
        </div>
        <slot v-else/>
    </div>
</template>

<script>
  export default {
    name: "BlockedContentWrapper",
    props: {
      blocked: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .blocked_message {
        font-size: 3rem;
        font-weight: lighter;
        flex-grow: 1;
        align-self: center;
        justify-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 40rem;
    }
</style>